import { useState, ChangeEvent, SyntheticEvent, forwardRef, ReactElement, Ref } from 'react';
import axios from 'axios';
import { DataGrid, GridEditRowsModel, GridRowId, MuiEvent } from '@mui/x-data-grid';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Dialog, Tabs, Tab, OutlinedInput, Alert, FormGroup, Checkbox, Button, Grid, Slider, FormControl, FormLabel, MenuItem, TextField, FormControlLabel, Modal, Box } from '@mui/material';
import TabPanel from '../components/Optimizer/tabPanel';
import { useLocation } from 'react-router-dom';
import {
    Settings as SettingsIcon,
    Add as AddIcon,
    Adjust as AdjustIcon
} from '@mui/icons-material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import { APP_TITLE, PAGE_TITLE_OPTIMIZE } from '../utils/constants';
import { DfsHelmet } from '../components/Helmet';
import ResultTabs from '../components/Optimizer/resultTabs';
import { playerColumns } from '../components/DraftKings/playersGrid';

import { Player } from '../types';
 
const stylePlayers = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

interface stateType {
    data: Player[],
    GameTypeId: number,
    sport: string
}

export const Optimizer = () => {

    const { state } = useLocation<stateType>();
    const [results, setResults] = useState([]);
    const [stats, setStats] = useState<string | null>(null);
    const [editRowsModel, setEditRowsModel] = useState<GridEditRowsModel>({});
    const teams: Object[] = [];
    const [stackTeam, setStackTeam] = useState<string[]>([]);
    const [stackSize, setStackSize] = useState<number | null>(null);
    const [iterations, setIterations] = useState(10);
    const [maxExposure, setMaxExposure] = useState<number | null>(null);
    const [maxPlayerRepeat, setMaxPlayerRepeat] = useState<number | null>(null);
    const [minSalaryCap, setMinSalaryCap] = useState<number | null>(null);
    const [reduceBudget, setReduceBudget] = useState<number | null>(null);
    
    const [forceCombo, setForceCombo] = useState<boolean>(false);
    const [allowTECombo, setallowTECombo] = useState<boolean>(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alert, setAlert] = useState<boolean>(false);

    state.data.forEach(function (d: any) {
        let obj: any = teams.find((o: any) => o.label === d.team);
        !(obj) && teams.push({label: d.team, value: d.team});
    });

    const getOptimizerResult = (data: Object) => {
        console.log(data);
        axios.post(process.env.REACT_APP_API_URL + '/optimize/pydfs', data, { 
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache'
            } 
        }).then(res => {
                if (res.data.status == 'error') {
                    setAlertMsg(res.data.msg);
                    setAlert(true);
                } else {
                    setAlert(false);
                }
                console.log(res.data);
                // get exposures if multiple iterations were calculated
                if ('stats' in res.data) {
                    setStats(res.data.stats);
                } else {
                    setStats(null);
                }

                // get lineups and add result tables for each
                if ('lineups' in res.data) {
                    const lineups: [] = res.data.lineups;
                    lineups.map((res: any, index: number) => {
                        const optimizeResult: any = [];
                        res.lineup.forEach((player: any) => {
                            // console.log("Searching for player with id: " + player["_player"]['id']);
                            let obj: any = state.data.find((o: any) => o.id == player["_player"]['id']);
                            // console.log("Found player: " + (obj));
                            const copy = Object.assign({}, obj);
                            copy["ppg_proj"] = player["_player"]["fppg"];
                            copy["salary"] = player["_player"]["salary"];
                            copy["slot"] = player['lineup_position'];
                            optimizeResult.push(copy);
                        });
                        res.players = optimizeResult;
                    })
                    setResults(lineups);
                }
            });
    };

    function handleEditCommit(id: GridRowId, event: MuiEvent) {
        let player: any = state.data.find((o: any) => o.id == id);
        try {
            player.projection.ppg_proj = editRowsModel[id].ppg_proj.value;
            player.projection.banned = editRowsModel[id].ban.value;
            player.projection.locked = editRowsModel[id].lock.value;
        } catch (error) {
            console.error(error);
        }        
    }

    function handleEditRowsModelChange(model: GridEditRowsModel) {
        setEditRowsModel(model);
      }

    const handleChangeStackTeam = (event: SelectChangeEvent<typeof stackTeam>) => {
        const {target: { value } } = event;
        setStackTeam(typeof value === 'string' ? value.split(',') : value);
      };

    const handleChangeStackSize = (event: ChangeEvent<HTMLInputElement>) => {
        try {
            setStackSize(parseInt(event.target.value));
        } catch {
            setStackSize(null); 
        }
    };

    const handleForceComboChange = (event: ChangeEvent<HTMLInputElement>) => {
        setForceCombo(event.target.checked);
    };

    const handleAllowTEComboChange = (event: ChangeEvent<HTMLInputElement>) => {
        setallowTECombo(event.target.checked);
    };

    const handleChangeIterations = (event: ChangeEvent<HTMLInputElement>) => {
        setIterations(parseInt(event.target.value));
    };

    const handleChangeMaxExposure = (event: ChangeEvent<HTMLInputElement>) => {
        setMaxExposure(parseInt(event.target.value));
    };

    const handleChangeMaxPlayerRepeat = (event: ChangeEvent<HTMLInputElement>) => {
        setMaxPlayerRepeat(parseInt(event.target.value));
    };

    const handleChangeMinSalaryCap = (event: ChangeEvent<HTMLInputElement>) => {
        setMinSalaryCap(parseInt(event.target.value));
    };

    const handleChangeReduceBudget = (event: ChangeEvent<HTMLInputElement>) => {
        setReduceBudget(parseInt(event.target.value));
    };
    
    // Settings dialog state and functions
    const [openSettings, setOpenSettings] = useState(false);
    const handleOpenSettings = () => setOpenSettings(true);
    const handleCloseSettings = () => setOpenSettings(false);

    // Players modal state and functions
    const [openPlayers, setOpenPlayers] = useState(false);
    const handleOpenPlayers = () => setOpenPlayers(true);
    const handleClosePlayers = () => setOpenPlayers(false);

    const [settingsTabValue, setSettingsTabValue] = useState(0);

    const handleTabValueChange = (event: SyntheticEvent, newValue: number) => {
        setSettingsTabValue(newValue);
    };

    const a11yProps = (index: number) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            <DfsHelmet>
                <title>{PAGE_TITLE_OPTIMIZE} | {APP_TITLE}</title>
            </DfsHelmet>
            <Grid container>
                <Grid item xs={3}></Grid>
                <Grid item xs={6} style={{ display: "flex", justifyContent: "space-between"}}>
                    <Button variant="contained" startIcon={<SettingsIcon />} onClick={handleOpenSettings}>
                        Settings
                    </Button>
                    <Button variant="contained" startIcon={<AdjustIcon />} onClick={handleOpenPlayers}>
                        Adjust Players
                    </Button>
                    <Button variant="contained" startIcon={<AddIcon />} onClick={
                        () => getOptimizerResult({
                            gameTypeId: state.GameTypeId,
                            sport: state.sport,
                            data: state.data,
                            stackSize: stackSize,
                            stackTeam: stackTeam,
                            iterations: iterations,
                            maxExposure: maxExposure,
                            maxPlayerRepeat: maxPlayerRepeat,
                            minSalaryCap: minSalaryCap,
                            reduceBudget: reduceBudget,
                            forceCombo: forceCombo,
                            allowTECombo: allowTECombo
                        })}>
                        Generate
                    </Button>
                </Grid>
                <Grid item xs={3}></Grid>
                <Dialog
                    fullScreen
                    open={openSettings}
                    onClose={handleCloseSettings}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar variant="dense">
                            <SettingsIcon />
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Settings
                            </Typography>
                            <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleCloseSettings}
                            aria-label="close"
                            >
                            <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={settingsTabValue} onChange={handleTabValueChange} aria-label="Settings tabs">
                                <Tab key={"tab-index-0"} label={"Stacking"} {...a11yProps(0)} />;
                                <Tab key={"tab-index-1"} label={"Exposure"} {...a11yProps(1)} />;
                                <Tab key={"tab-index-2"} label={"Filters"} {...a11yProps(2)} />;
                            </Tabs>
                        </Box>
                        <TabPanel key={"tab-panel-index-0"} value={settingsTabValue} index={0} >
                            <div style={{ height: '100%', width: '100%' }}>
                                <FormControl sx={{ m: 1, width: '98%' }} style={{ display: "flex", justifyContent: "center"}}>
                                    <FormLabel component="legend">Stack size</FormLabel>
                                    <TextField
                                        id="team-stack-number"
                                        type="number"
                                        value={stackSize}
                                        onChange={handleChangeStackSize}
                                        size="small"
                                    />
                                    <FormLabel component="legend">Stack team</FormLabel>
                                    {/* <InputLabel id="team-stack-select-label">Stack Teams</InputLabel> */}
                                    <Select
                                        // labelId="team-stack-select-label"
                                        id="team-stack-select"
                                        multiple
                                        value={stackTeam}
                                        onChange={handleChangeStackTeam}
                                        input={<OutlinedInput label="Stack" />}
                                        >
                                        {teams?.map((option: any) => {
                                            return (
                                                <MenuItem key={option.value} value={option.value}>
                                                {option.label ?? option.value}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <FormLabel component="legend">Stack options</FormLabel>
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                        value="forceCombo"
                                        control={<Checkbox checked={forceCombo} onChange={handleForceComboChange} />}
                                        label="force Combo"
                                        labelPlacement="end"
                                        />
                                        <FormControlLabel
                                        value="allowTE"
                                        control={<Checkbox checked={allowTECombo} onChange={handleAllowTEComboChange}  />}
                                        label="allow TE Combo"
                                        labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </div>
                        </TabPanel>
                        <TabPanel key={"tab-panel-index-1"} value={settingsTabValue} index={1} >
                            <div style={{ height: '100%', width: '100%' }}>
                                <FormControl sx={{ m: 1, width: '98%' }} style={{ display: "flex", justifyContent: "center"}}>
                                    <FormLabel component="legend">Iterations (only the top 10 lineups will be presented)</FormLabel>
                                    <TextField
                                        id="optimizer-iterations"
                                        type="number"
                                        value={iterations}
                                        onChange={handleChangeIterations}
                                        size="small"
                                    />
                                    <FormLabel component="legend">Max. Player Exposure %</FormLabel>
                                    <TextField
                                        id="optimizer-exposure"
                                        type="number"
                                        value={maxExposure}
                                        onChange={handleChangeMaxExposure}
                                        size="small"
                                    />
                                </FormControl>
                            </div>
                        </TabPanel>
                        <TabPanel key={"tab-panel-index-2"} value={settingsTabValue} index={2} >
                            <div style={{ height: '100%', width: '100%' }}>
                                <FormControl sx={{ m: 1, width: '98%' }} style={{ display: "flex", justifyContent: "center"}}>
                                    <FormLabel component="legend">Maximum repeating players</FormLabel>
                                    <TextField
                                        id="optimizer-player-repeat"
                                        type="number"
                                        value={maxPlayerRepeat}
                                        onChange={handleChangeMaxPlayerRepeat}
                                        size="small"
                                    />
                                    <FormLabel component="legend">Minimum salary cap</FormLabel>
                                    <TextField
                                        id="optimizer-min-salary-cap"
                                        type="number"
                                        value={minSalaryCap}
                                        onChange={handleChangeMinSalaryCap}
                                        size="small"
                                    />
                                    <FormLabel component="legend">Reduce budget by</FormLabel>
                                    <TextField
                                        id="optimizer-reduce-budget"
                                        type="number"
                                        value={reduceBudget}
                                        onChange={handleChangeReduceBudget}
                                        size="small"
                                    />
                                </FormControl>
                            </div>
                        </TabPanel>
                    </Box>
                </Dialog>
                <Modal
                    open={openPlayers}
                    onClose={handleClosePlayers}
                    aria-labelledby="modal-players-title"
                    aria-describedby="modal-players-description"
                >
                <Box sx={stylePlayers}>
                    <DataGrid
                        autoPageSize
                        pagination
                        rows={state.data}
                        columns={playerColumns}
                        editRowsModel={editRowsModel}
                        editMode="row"
                        onRowEditCommit={handleEditCommit}
                        onEditRowsModelChange={handleEditRowsModelChange}
                    />
                </Box>
                </Modal>
                <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between"}}>
                    <Grid container>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl component="fieldset">
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {alert && <Alert severity="error">{alertMsg}</Alert>}
                    {!alert && <ResultTabs results={results} stats={stats}/>}
                </Grid>
            </Grid>
        </>
    );

}
