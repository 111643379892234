import { useState, ChangeEvent } from 'react';
import { styled, Paper, Typography, Grid, Alert } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { APP_TITLE, PAGE_TITLE_CONTESTS } from '../utils/constants';
import { DfsHelmet } from '../components/Helmet';
import DraftGroups from "../components/DraftKings/dkDraftGroups";

export const Contests = () => {

    return (
        <>
        <DfsHelmet>
            <title>{PAGE_TITLE_CONTESTS} | {APP_TITLE}</title>
        </DfsHelmet>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DraftGroups />
        </LocalizationProvider>
        </>
    );
};
