import { GridValueGetterParams, GridColDef } from '@mui/x-data-grid';

function getFullName(params: GridValueGetterParams) {
    let status: string = (params.getValue(params.id, 'status')=="None") ? '' : `${' (' + params.getValue(params.id, 'status') + ')'}`;
    let name: string = params.row.displayName;
    return name + " " + status
}

function renderFullName(params: GridValueGetterParams) {
    switch(params.getValue(params.id, 'status')) {
        case "None":
            return <span>{params.value}</span>;
        case "Q":
            return <span style={{color: "orange"}}>{params.value}</span>;
        default:
            return <span style={{color: "red"}}>{params.value}</span>;
    }
}

export const playerColumns: GridColDef[] = [
    { 
        field: 'image_url',
        headerName: ' ',
        width: 60,
        editable: false,
        renderCell: (params) => <img src={params.value} alt="n/a"/>,
    },
    { field: 'id', headerName: 'ID', minWidth: 100, editable: false },
    {
        field: 'fullName',
        headerName: 'Name',
        minWidth: 160,
        flex: 1,
        valueGetter: getFullName,
        renderCell: renderFullName,
    },
    { 
        field: 'slot',
        headerName: 'Slot',
        minWidth: 80,
        editable: false
    },
    { 
        field: 'position',
        headerName: 'Position',
        minWidth: 80,
        editable: false
    },
    { 
        field: 'team',
        headerName: 'Team',
        minWidth: 80,
        editable: false
    },
    { 
        field: 'competition',
        headerName: 'Competition',
        minWidth: 120,
        flex: 1,
        editable: false
    },
    { field: 'opponentRankDesc', headerName: 'Opp. Rank', minWidth: 120, editable: false },
    { field: 'salary', headerName: 'Salary', minWidth: 120, editable: false },
    { field: 'fpts', headerName: 'DK FPTS', minWidth: 100, editable: false },
    { 
        field: 'ppg_proj',
        headerName: 'Projection',
        minWidth: 100,
        editable: true,
        type: 'number'
    },
    { 
        field: 'value',
        headerName: 'Value',
        minWidth: 100,
        editable: false,
        type: 'number',
        valueGetter: (params) => parseFloat(`${params.getValue(params.id, 'ppg_proj') || 0}`) / parseFloat(`${params.getValue(params.id, 'salary') || 1}`) * 1000
    },
    { 
        field: 'hvalue',
        headerName: 'H-Value',
        minWidth: 100,
        editable: false,
        type: 'number',
        valueGetter: (params) => parseFloat(`${params.getValue(params.id, 'ppg_proj') || 0}`) * 1.73 / parseFloat(`${params.getValue(params.id, 'salary') || 1}`) * 2000
    },
    { 
        field: 'ban',
        headerName: 'Ban',
        minWidth: 80,
        editable: true,
        type: 'boolean'
    },
    {
        field: 'lock',
        headerName: 'Lock',
        minWidth: 80,
        editable: true,
        type: 'boolean'
    },
];