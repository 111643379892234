import React from 'react';
import axios from 'axios';
import { DataGrid, GridColDef, GridOverlay } from '@mui/x-data-grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Grid, Button, LinearProgress } from '@mui/material';
import { Link } from 'react-router-dom';


const handleCellClick = (param: any, event: any) => {
    event.stopPropagation();
};
  
const handleRowClick = (param: any, event: any) => {
    event.stopPropagation();
};

class DraftGroups extends React.Component<any, any> {
    _isMounted = false;

    constructor(props: any) {
        super(props);
        this.handleSportsChange = this.handleSportsChange.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.state = {
            draftGroups: [],
            draftGroupId: null,
            StartDate: null,
            loadingDraftGroups: false,
            Sports: null,
            Sport: '',
            filterModel: {items: [{ id: 1, columnField: 'Sport', operatorValue: 'contains', value: '' }]}
        };
    }

    CustomLoadingOverlay() {
        return (
          <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
              <LinearProgress />
            </div>
          </GridOverlay>
        );
    }

    handleSportsChange(event: React.MouseEvent<HTMLElement>, newSport: string,) {
        this.updateFilter(newSport);
        this.setState({
            Sport: newSport,
            });
      }

    updateFilter (filter: string) {
        this.setState({
            filterModel: {items: [{ id: 1, columnField: 'Sport', operatorValue: 'contains', value: filter }]}
        }
        );
      };

    componentDidMount() {
        this._isMounted = true;
        this.setState({
            loadingDraftGroups: true
            });
        axios.get(process.env.REACT_APP_API_URL + '/dk/draftGroups')
            .then(res => {
            const result = res.data;
            if (this._isMounted) {
                this.setState({
                    draftGroups: result.data
                    });
            }
        })
        .finally(()=> {
            this.setState({
                loadingDraftGroups: false
                });
         });

        axios.get(process.env.REACT_APP_API_URL + '/dk/sports')
            .then(res => {
            const result = res.data;
            if (this._isMounted) {
                this.setState({
                    Sports: result.data
                    });
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
      }

    render() {
        const columns: GridColDef[] = [
            { field: 'id', headerName: 'ID', minWidth: 80, editable: false },
            { field: 'Sport', headerName: 'Sport', minWidth: 120, flex:1, editable: false },
            { 
                field: 'GameTypeId',
                headerName: 'Contest',
                valueGetter: (params) => { return params.row.GameType.Name; },
                minWidth: 120,
                flex:1,
                editable: false
            },
            { field: 'ContestStartTimeSuffix', headerName: 'Contest Details', minWidth: 160, flex:1, editable: false },
            { 
                field: 'StartDate',
                headerName: 'Start Date',
                type: 'dateTime',
                valueGetter: ({ value }) => value && new Date(value),
                minWidth: 200,
                flex:1,
                editable: false
            },
            {
                field: "Action",
                minWidth: 120,
                renderCell: (cellValues) => {
                  return (
                    <Link 
                        style={{ textDecoration: 'none' }}
                        to={{ pathname: '/players', state: {
                            draftGroupId: cellValues.row.id,
                            StartDate: cellValues.row.StartDate.slice(0,10),
                            GameTypeId: cellValues.row.GameTypeId,
                            sport: cellValues.row.Sport
                        }}}>
                        <Button variant="contained"> 
                            Players
                        </Button>
                    </Link >
                  );
                }
            },
        ];

        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <ToggleButtonGroup
                        color="primary"
                        value={this.state.Sport}
                        exclusive
                        onChange={this.handleSportsChange}
                    >
                        <ToggleButton value="">All</ToggleButton>
                        {this.state.Sports && this.state.Sports.map((res: any, index: number) => {
                            return <ToggleButton key={"sports-index-" + index} value={res.name}>{res.name}</ToggleButton>;
                        })}
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} height="700px">
                    <DataGrid
                        autoPageSize
                        pagination
                        rows={this.state.draftGroups}
                        columns={columns}
                        components={{
                            LoadingOverlay: this.CustomLoadingOverlay
                        }}
                        onCellClick={handleCellClick}
                        onRowClick={handleRowClick}
                        loading={this.state.loadingDraftGroups}
                        filterModel={this.state.filterModel}
                    />
                </Grid>
            </Grid>
        );
    }

}

export default DraftGroups;
