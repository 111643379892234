import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Grid, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { APP_TITLE, PAGE_TITLE_PLAYERS } from '../utils/constants';
import { DfsHelmet } from '../components/Helmet';
import Draftables from '../components/DraftKings/dkDraftables';

interface playersStateType {
    draftGroupId: number,
    StartDate: string,
    GameTypeId: number,
    sport: string
}



export const Players = () => {

    const { state } = useLocation<playersStateType>();
    const [data, setData] = useState<Array<Object>>([]);
    const [fileId, setFileId] = useState<string>('');
    const [files, setFiles] = useState<Array<Object>>([]);

    function updateData(newData: Array<Object>) {
        setData(newData);
    }

    const handleChange = (event: SelectChangeEvent) => {
        setFileId(event.target.value as string);
        axios.get(process.env.REACT_APP_API_URL + '/projection/file/' + event.target.value)
        .then(res => {
            let proj = res.data.data;
            let newData: Object[] = [];
            data.forEach( (d: any) => {
                let p = proj.find((o: any) => o.playerId == d.playerId);
                if (p) {
                    d.ppg_proj = d.slot=='CPT' ? p.ppg_proj * 1.5 : p.ppg_proj;
                } else {
                    d.ppg_proj = 0;
                }
                newData.push(d);
            });
            updateData(newData);
        });
    };

    useEffect(() => { axios.get(process.env.REACT_APP_API_URL + '/projection/files/' + state.sport).then(res => { setFiles(res.data.data); })}, [state.sport]);

    return (
        <>
        <DfsHelmet>
            <title>{PAGE_TITLE_PLAYERS} | {APP_TITLE}</title>
        </DfsHelmet>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={0}>
                <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-start" }}>
                    <Link 
                        style={{ textDecoration: 'none' }}
                        to={{ 
                            pathname: '/optimize/manual',
                            state: {
                                data: data,
                                GameTypeId: state.GameTypeId
                        }}}>
                        <Button variant="contained">
                            Manual
                        </Button>
                    </Link >
                </Grid>
                <Grid item xs={4} style={{ display: "flex", justifyContent: "center " }}>
                <FormControl fullWidth>
                    <InputLabel id="projection-file-select-label">Projection File</InputLabel>
                    <Select
                        labelId="projection-file-select-label"
                        id="projection-file-select"
                        value={fileId}
                        label="Projection File"
                        onChange={handleChange}
                    >
                    {files?.map((file: any) => {
                        return (
                            <MenuItem key={file.id} value={file.id}>
                            {file.filename}
                            </MenuItem>
                        );
                    })}
                    </Select>
                </FormControl>
                </Grid>
                <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Link 
                        style={{ textDecoration: 'none' }}
                        to={{ 
                            pathname: '/optimize',
                            state: {
                                data: data,
                                GameTypeId: state.GameTypeId,
                                sport: state.sport
                        }}}>
                        <Button variant="contained">
                            Optimize
                        </Button>
                    </Link >
                </Grid>
            </Grid>
            <Grid container spacing={0} height="95%">
                { state && <Draftables key={state.draftGroupId} updateData={updateData} draftGroupId={state.draftGroupId} StartDate={state.StartDate} />}
            </Grid>
        </LocalizationProvider>
        </>
    );
};
