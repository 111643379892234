import React from 'react';
import { Box, Tabs, Tab, Grid, Button }  from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TabPanel from './tabPanel';
import { playerColumns } from '../DraftKings/playersGrid';
import { exposureColumns } from '../DraftKings/exposureGrid';
import SaveIcon from '@mui/icons-material/Save';

class ResultTabs extends React.Component<any, any> {
    _isMounted = false;

    constructor(props: any) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.state = {
            value: 0
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    a11yProps(index: number) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    handleChange(event: React.SyntheticEvent, newValue: number) {
        this.setState({
            value: newValue
        });
    };

    handleSave(players: any) {
        console.log('saving lineup');
        console.log(players);
    };

    render() {
        return (
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={this.state.value} onChange={this.handleChange} aria-label="Lineup tabs">
                        { (this.props.stats) ? <Tab key={"tab-index-0"} label={"Stats"} {...this.a11yProps(0)} /> : null } 
                        {this.props.results.map((res: any, index: number) => {
                            return <Tab key={"tab-index-" + index} label={"Lineup " + (index+1)} {...this.a11yProps(index)} />;
                        })}
                    </Tabs>
                </Box>
                {(this.props.stats) 
                    ? <TabPanel key={"tab-panel-index-0"} value={this.state.value} index={0} >
                        <div style={{ height: 550, width: '100%' }}>
                            {JSON.stringify(this.props.stats)}
                        </div>
                    </TabPanel>
                    : null
                }
                {this.props.results.map((res: any, index: number) => {
                    (this.props.stats) ? index += 1 : index;
                    return (
                        <TabPanel key={"tab-panel-index-" + index} value={this.state.value} index={index}>
                            <Grid container>
                                <Grid item xs={2}>
                                    Total Salary: ${res.salary_total}
                                </Grid>
                                <Grid item xs={2}>
                                    Fantasy Points: {res.fppg_total}
                                </Grid>
                                <Grid item xs={2}>
                                    Fantasy Points Floor: {res.fppg_floor_total}
                                </Grid>
                                <Grid item xs={2}>
                                    Fantasy Points Ceil: {res.fppg_ceil_total}
                                </Grid>
                                <Grid item xs={4}>
                                    Matrix Lineup: { String(res.matrix_lineup) }
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{ height: 550, width: '100%' }}>
                                        <DataGrid
                                            autoPageSize
                                            pagination
                                            rows={res.players}
                                            columns={playerColumns}
                                            hideFooter={true}
                                            />
                                    </div>
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <Button variant="contained" startIcon={<SaveIcon />} onClick={() => this.handleSave(res)}>
                                        Save
                                    </Button>
                                </Grid> */}
                            </Grid>
                        </TabPanel>
                    );
                })}
            </Box>
        );
    }

}

export default ResultTabs;
