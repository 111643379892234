import React from 'react';
import { Box, Typography }  from '@mui/material';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

class TabPanel extends React.Component<any, any> {
    _isMounted = false;

    constructor(props: TabPanelProps) {
        super(props);
        this.state = {
            value: 0
        };
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    render() {
        return (
        <div
            role="tabpanel"
            hidden={this.props.value !== this.props.index}
            id={`simple-tabpanel-${this.props.index}`}
            aria-labelledby={`simple-tab-${this.props.index}`}
            {...this.props.other}
        >
            {this.props.value === this.props.index && (
            <Box sx={{ p: 3 }}>
                <Typography>{this.props.children}</Typography>
            </Box>
            )}
        </div>
        );
    }
}

export default TabPanel;
