import React from 'react';
import axios from 'axios';
import { DataGrid, GridRowId, MuiEvent, GridEditRowsModel } from '@mui/x-data-grid';
import { Grid, Alert, LinearProgress } from '@mui/material';


import { playerColumns } from './playersGrid';

import { CustomLoadingOverlay } from '../Common/LoadingOverlay';

class Draftables extends React.Component<any, any> {
    _isMounted = false;

    constructor(props: any) {
        super(props);
        this.handleEditRowsModelChange = this.handleEditRowsModelChange.bind(this);
        this.handleEditCommit = this.handleEditCommit.bind(this);
        //this.getDffProjections = this.getDffProjections.bind(this);
        this.state = {
            draftables: [],
            editRowsModel: {},
            loading: false
        };
    }


    handleEditCommit(id: GridRowId, event: MuiEvent) {
        let player = this.state.draftables.find((o: any) => o.id == id);
        try {
            player.ppg_proj = this.state.editRowsModel[id].ppg_proj.value;
            player.ban = this.state.editRowsModel[id].ban.value;
            player.lock = this.state.editRowsModel[id].lock.value;
            player.value = player.ppg_proj/player.salary*1000;
            player.hvalue = player.ppg_proj*1.73/player.salary*2000;
        } catch (error) {
            console.error(error);
        }
        this.props.updateData(this.state.draftables);
    }

    handleEditRowsModelChange(model: GridEditRowsModel) {
        this.setState({
            editRowsModel: model,
            });
      }

    componentDidMount() {
        this._isMounted = true;

        this.setState({
            loading: true
        });

        if (this.props.draftGroupId!=null) {
            var url = process.env.REACT_APP_API_URL + '/dk/draftGroups/' + this.props.draftGroupId;

            axios.get(url)
                .then(res => {
                const result = res.data;
                if (this._isMounted) {
                    this.props.updateData(result.data);

                    this.setState({
                        draftables: result.data
                        });

                }
            })
            .finally(()=> {
                this.setState({
                    loading: false
                    });
             });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        return (
            <Grid container spacing={0}>
                <DataGrid
                    components={{
                        LoadingOverlay: CustomLoadingOverlay
                    }}
                    autoPageSize
                    pagination
                    rows={this.state.draftables}
                    columns={playerColumns}
                    editRowsModel={this.state.editRowsModel}
                    editMode="row"
                    onRowEditCommit={this.handleEditCommit}
                    onEditRowsModelChange={this.handleEditRowsModelChange}
                    loading={this.state.loading}
                />
            </Grid>
        );
    }

}

export default Draftables;
