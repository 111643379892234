import { Typography } from '@mui/material';
// import { useContext } from 'react';
import { DfsHelmet } from '../components/Helmet';
import { useState } from 'react';
import { ChangeEvent, MouseEvent } from 'react';
import axios from 'axios';
import { login, useAuth, logout } from "../components/Common/auth"


// import { AppContext } from '../contexts';
import { APP_TITLE, PAGE_TITLE_HOME } from '../utils/constants';

export const Home = () => {
  // const context = useContext(AppContext);
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  
  const [logged] = useAuth();

  const onSubmitClick = (e: MouseEvent<HTMLElement>)=>{
    e.preventDefault()

    let opts = {
      'username': username,
      'password': password
    }

    axios.post(process.env.REACT_APP_API_URL + '/login', JSON.stringify(opts), { 
      headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
      } 
      }).then(res => {
          if (res.data.access_token){
              login(res.data.access_token)
              console.log(res.data.access_token)          
            }
            else {
              console.log("Please type in correct username/password")
            }
      });
  }

  const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value)
  }

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  return (
    <>
      <DfsHelmet>
        <title>
          {PAGE_TITLE_HOME} | {APP_TITLE}
        </title>
      </DfsHelmet>
      <Typography variant="h4">Welcome at DFS Optimizer</Typography>
      <div>
      <h2>Login</h2>
      {!logged? <form action="#">
        <div>
          <input type="text" 
            placeholder="Username" 
            onChange={handleUsernameChange}
            value={username} 
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Password"
            onChange={handlePasswordChange}
            value={password}
          />
        </div>
        <button onClick={onSubmitClick} type="submit">
          Login Now
        </button>
      </form>
      : <button onClick={() => logout()}>Logout</button>}
    </div>
    </>
  );
};
