import { useState, ChangeEvent, MouseEvent, SyntheticEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { FormControlLabel, Checkbox, Box, Tabs, Tab, Grid, TextField, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { DataGrid, GridFilterModel, GridValueGetterParams, GridColDef } from '@mui/x-data-grid';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { APP_TITLE, PAGE_TITLE_PLAYERS } from '../utils/constants';
import { DfsHelmet } from '../components/Helmet';

import { Player } from '../types';

interface stateType {
    data: Player[],
    GameTypeId: number
}

function getFullName(params: GridValueGetterParams) {
    let status: string = (params.getValue(params.id, 'status')=="None") ? '' : `${' (' + params.getValue(params.id, 'status') + ')'}`;
    let name: string = params.row.player.displayName;
    return name + " " + status
}

function renderFullName(params: GridValueGetterParams) {
    switch(params.getValue(params.id, 'status')) {
        case "None":
            return <span>{params.value}</span>;
        case "Q":
            return <span style={{color: "orange"}}>{params.value}</span>;
        default:
            return <span style={{color: "red"}}>{params.value}</span>;
    }
}

function renderCompetition(params: GridValueGetterParams) {
    return (params.row.player.team.teamAbbreviation == params.row.competition.awayTeam.teamAbbreviation) ?  <div><b>{params.row.competition.awayTeam.teamAbbreviation}</b> @ {params.row.competition.homeTeam.teamAbbreviation}</div>  : <div>{params.row.competition.awayTeam.teamAbbreviation} @ <b>{params.row.competition.homeTeam.teamAbbreviation}</b></div>;
}

export const OptimizerManual = () => {

    const { state } = useLocation<stateType>();
    const uniqueSlots = [...Array.from(new Set(state.data.map((item: any) => item.rosterSlot.slot)))];
    const [players, setPlayers] = useState<Array<Object>>(state.data.filter((item: any) => item.rosterSlot.slot == uniqueSlots[0]));
    const [lineup, setLineup] = useState<Array<Object>>([]);
    const [salary, setSalary] = useState<number>(0);
    const [projection, setProjection] = useState<number>(0);
    const [showBase, setShowBase] = useState<boolean>(false);
    const [tab, setTab] = useState<number>(0);
    const [player, setPlayer] = useState<string>('');
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
          { id: 1, columnField: 'fullName', operatorValue: 'contains', value: '' }
        ],
      });

    const updateFilter = (filter: string) => {
        setFilterModel({
            items: [
              { id: 1, columnField: 'fullName', operatorValue: 'contains', value: filter }
            ],
        });
      };

    const handlePlayerChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPlayer(event.target.value);
        updateFilter(event.target.value);
      };

    const handleTabChange = (event: SyntheticEvent, newValue: number) => {
        setTab(newValue);
        setPlayers(state.data.filter((item: any) => item.rosterSlot.slot == uniqueSlots[newValue]));
    };

    const handleBaseChange = (event: ChangeEvent<HTMLInputElement>) => {
        setShowBase(event.target.checked);
      };

    function a11yProps(index: number) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const playerColumnsShort: GridColDef[] = [
        {
            field: " ",
            width: 40,
            renderCell: (cellValues) => {
                const onClickAdd = () => {
                    setSalary(salary + cellValues.row.salary);
                    setProjection(projection + cellValues.row.projection.ppg_proj);
                    setLineup([...lineup, cellValues.row]);
                };

                return (
                    <IconButton aria-label="add" onClick={onClickAdd}>
                        <AddIcon />
                    </IconButton>
                );
            }
        },
        { 
            field: 'image_url',
            headerName: ' ',
            width: 60,
            editable: false,
            valueGetter: (params) => { return params.row.player.playerImage50; },
            renderCell: (params) => <img src={params.value} alt="n/a"/>,
        },
        {
            field: 'fullName',
            headerName: 'player',
            minWidth: 160,
            flex: 1,
            valueGetter: getFullName,
            renderCell: renderFullName,
          },
          { 
            field: 'slot',
            headerName: 'slot',
            width: 70,
            editable: false,
            valueGetter: (params) => { return params.row.rosterSlot.slot; }
        },
        { 
            field: 'competitionName',
            headerName: 'opp',
            width: 100,
            editable: false,
            renderCell: renderCompetition
        },
        { field: 'opponentRankDesc', headerName: 'oprk', width: 70, editable: false },
        { 
            field: 'salary',
            headerName: 'salary',
            width: 80,
            editable: false,
            renderCell: (params) => { return "$" + params.row.salary }
        },
        { 
            field: 'baseSalary',
            headerName: 'base',
            width: 80,
            editable: false,
            renderCell: (params) => { return (params.row.rosterSlot.slot=='CPT') ? "$" + params.row.salary/1.5 : "$" + params.row.salary },
            hide: !showBase
        },
        { 
            field: 'ppg_proj',
            headerName: 'ffpg',
            width: 80,
            editable: true,
            type: 'number',
            valueGetter: (params) => { return (params.row.projection) ? params.row.projection.ppg_proj : 0; }
        },
    ];

    const lineupColumnsShort: GridColDef[] = [
        {
            field: " ",
            width: 40,
            renderCell: (cellValues) => {
                const onClickRemove = () => {
                    console.log(cellValues.row);
                    setSalary(salary - cellValues.row.salary);
                    setProjection(projection - cellValues.row.projection.ppg_proj);
                    setLineup(lineup.filter((item: any) => item.id !== cellValues.row.id));
                };

                return (
                    <IconButton aria-label="remove" onClick={onClickRemove}>
                        <RemoveIcon />
                    </IconButton>
                );
            }
        },
        { 
            field: 'image_url',
            headerName: ' ',
            width: 60,
            editable: false,
            valueGetter: (params) => { return params.row.player.playerImage50; },
            renderCell: (params) => <img src={params.value} alt="n/a"/>,
        },
        {
            field: 'fullName',
            headerName: 'player',
            minWidth: 160,
            flex: 1,
            valueGetter: getFullName,
            renderCell: renderFullName,
          },
          { 
            field: 'slot',
            headerName: 'slot',
            width: 70,
            editable: false,
            valueGetter: (params) => { return params.row.rosterSlot.slot; }
        },
        { 
            field: 'competitionName',
            headerName: 'opp',
            width: 100,
            editable: false,
            renderCell: renderCompetition
        },
        { field: 'opponentRankDesc', headerName: 'oprk', width: 70, editable: false },
        { 
            field: 'salary',
            headerName: 'salary',
            width: 80,
            editable: false,
            renderCell: (params) => { return "$" + params.row.salary; }
        },
        { 
            field: 'ppg_proj',
            headerName: 'ffpg',
            width: 80,
            editable: true,
            type: 'number',
            valueGetter: (params) => { return (params.row.projection) ? params.row.projection.ppg_proj : 0; }
        },
    ];

    return (
        <>
        <DfsHelmet>
            <title>{PAGE_TITLE_PLAYERS} | {APP_TITLE}</title>
        </DfsHelmet>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={0}>
                <Grid xs={6}>
                    <TextField id="outlined-basic" label="Search Player" variant="outlined" value={player} onChange={handlePlayerChange} />
                    <FormControlLabel control={<Checkbox checked={showBase} onChange={handleBaseChange} />} label="show Base Salary" />
                    <Box sx={{ width: '100%' }}>
                        <Tabs value={tab} onChange={handleTabChange} aria-label="Lineup tabs">
                            {uniqueSlots.map((slot: any, index: number) => {
                                return <Tab key={"tab-index-" + index} label={slot} {...a11yProps(index)} />;
                            })}
                        </Tabs>
                    </Box>
                </Grid>
                <Grid xs={6}>
                    Total Salary: ${salary} Total Projection: {Math.round(projection * 100) / 100}
                </Grid>
            </Grid>
            <Grid container spacing={0} height="80%">
                <Grid xs={6}>
                    <DataGrid
                        rows={players}
                        columns={playerColumnsShort}
                        // editRowsModel={editRowsModel}
                        editMode="row"
                        // onRowEditCommit={handleEditCommit}
                        // onEditRowsModelChange={handleEditRowsModelChange}
                        filterModel={filterModel}
                        rowsPerPageOptions={[]}
                        autoPageSize
                    />
                </Grid>
                <Grid xs={6}>
                    <DataGrid
                        rows={lineup}
                        columns={lineupColumnsShort}
                        editMode="row"
                    />
                </Grid>
            </Grid>
        </LocalizationProvider>
        </>
    );
};