import { FC } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export const DfsHelmet: FC = ({ children }) => {
    return (
        <HelmetProvider>
            <Helmet>
                {children}
            </Helmet>
        </HelmetProvider>
    );
};
    