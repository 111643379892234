import {
  LocalFireDepartment as LocalFireDepartmentIcon,
  SportsFootball as SportsFootballIcon,
  People as PeopleIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  Home as HomeIcon,
  BarChartOutlined as DashboardIcon,
} from '@mui/icons-material';

import { Home } from '../pages/Home';
import { Contests } from '../pages/Contests';
import { Players } from '../pages/Players';
import { Optimizer } from '../pages/Optimizer';
import { OptimizerManual } from '../pages/OptimizerManual';

import { Route } from '../types/Route';

const routes: Array<Route> = [
  {
    key: 'router-home',
    title: 'Home',
    description: 'Home',
    component: Home,
    path: '/',
    isEnabled: true,
    icon: HomeIcon,
    appendDivider: true,
  },
  {
    key: 'router-contests',
    title: 'Contests',
    description: 'Contests',
    component: Contests,
    path: '/contests',
    isEnabled: true,
    icon: SportsFootballIcon,
  },
  {
    key: 'router-players',
    title: 'Players',
    description: 'Players',
    component: Players,
    path: '/players',
    isEnabled: true,
    icon: PeopleIcon,
  },  
  {
    key: 'router-optimize',
    title: 'Optimize',
    description: 'Lineup Optimizers',
    isEnabled: true,
    icon: DashboardIcon,
    appendDivider: true,
    subRoutes: [
      {
        key: 'router-optimize-automatic',
        title: 'Optimize Automatic',
        description: 'Optimize Automatic',
        component: Optimizer,
        path: '/optimize',
        isEnabled: true,
        icon: TrendingUpIcon,
      },
      {
        key: 'router-optimize-manual',
        title: 'Optimize Manual',
        description: 'Optimize Manual',
        component: OptimizerManual,
        path: '/optimize/manual',
        isEnabled: true,
        icon: TrendingDownIcon,

      },
    ],
  },
];

export default routes;
