// APP TEXT
export const APP_TITLE = 'DFS Optimizer';
export const APP_DESCRIPTION = 'Daily Fanatsy Sports Optimizer';
export const FOOTER_TEXT = `${new Date().getFullYear()} Proudly made by FlyingK`;
// PAGES TITLE
export const PAGE_TITLE_HOME = 'DFS - Home';
export const PAGE_TITLE_DASHBOARD = 'DFS - Dashboard';
export const PAGE_TITLE_CONTESTS = 'DFS - Contests';
export const PAGE_TITLE_PLAYERS = 'DFS - Players';
export const PAGE_TITLE_OPTIMIZE = 'DFS - Optimize';
// UI CONSTANTS
export const FOOTER_HEIGHT = 30;
export const HEADER_HEIGHT = 60;
export const DRAWER_WIDTH = 250;
// APP THEME
export const DARK_MODE_THEME = 'dark';
export const LIGHT_MODE_THEME = 'light';
